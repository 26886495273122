import { customerApi } from '@esg/business-crm';
import { GetAllCustomerResponseItem } from '@esg/business-crm/api/customer';
import {
    ColumnActions,
    FlexBox,
    TableCustom,
    globalDrawerState,
    globalModalState,
    useHttpQuery,
    useLocalesFormat,
    usePagination,
} from '@esg/ui';
import { TableColumnsType } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState ,useResetRecoilState } from 'recoil';

import { dayjs } from '@esg/shared';
import { viewRequests } from '@esg/framework';
import { CreateCustomerBtn } from './children/CreateCustomerBtn';
import { CustomerFullNameColumn } from './children/CustomerFullNameColumn';
import FilterCustomerForm from './children/FilterCustomerForm';
import { ActionCustomer } from './children/ActionCustomer';
import { CustomerForm } from '@/components/form/customer/CustomerForm';
import { PageLayout } from '@/layouts/page-layout/PageLayout';
import { translateCodes } from '@/locales';
import { CUSTOMER_DETAIL_URL } from '@/configs';
import { useCustomerDelete } from '@/hooks/useCustomerDelete';
import { CustomerDeleteConfirm } from '@/components/customer/CustomerDeleteConfirm';

export interface FilterCustomerParams {
    readonly sourceIds?: string[];
    readonly groupIds?: string[];
    readonly date?: string;
}

export const CustomerList = () => {
    const { t } = useTranslation();
    const [filterParams, setFilterParams] = useState<FilterCustomerParams>();
    const [searchParams, setSearchParams] = usePagination();

    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const resetGlobalModal = useResetRecoilState(globalModalState);
    const { formatDate } = useLocalesFormat();

    const {
        data: customers,
        isFetching,
        isLoading,
        refetch,
    } = useHttpQuery(customerApi.getAllCustomers, {
        query: {
            ...searchParams,
            ...filterParams,
            orderBy: 'profileCreationTime',
            orderDirection: 'desc',
        }
    });
    
    const onDelete = useCustomerDelete(refetch);

    const handleDelete = React.useCallback(
        (customer: GetAllCustomerResponseItem) => {
            
            setGlobalModal({
                content: (
                    <CustomerDeleteConfirm {...{ customer }} />
                ),
                isOpen: true,
                onOk: () => {
                    onDelete(customer.id);
                    viewRequests.showNotification.send({
                        type: 'success',
                        title: t(translateCodes.DELETE_CLIENT_SUCCESS),
                        message: t(translateCodes.DELETE_CLIENT_SUCCESS),
                    });
                },
                showModalDelete: true,
            });
        },
        [onDelete, setGlobalModal, t]
    );

    const onEdit = React.useCallback(
        (values: GetAllCustomerResponseItem) => {
            setDrawerState({
                subtitle: t(translateCodes.CREATED_ON) + ' ' + formatDate(values?.profileCreationTime ?? ''),
                isOpen: true,
                titleTransCode: t(translateCodes.EDIT_CUSTOMER),
                content: (
                    <CustomerForm 
                        onDeleted={()=> handleDelete(values)}
                        onSuccess={()=>{
                            refetch();
                            resetDrawerState();
                        }} 
                        defaultValues={values} 
                    />
                ),
            });
        },
        [setDrawerState, t, formatDate, handleDelete, refetch, resetDrawerState]
    );

    const showFilterModal = () => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: translateCodes.FILTER,
            content: <FilterCustomerForm
                defaultValues={filterParams}
                handleSubmit={(value) => {
                    setFilterParams(value);
                    resetGlobalModal();
                    refetch();
                }}
                onCancel={() => resetGlobalModal()}
            />
        });
    };

    const columns: TableColumnsType<GetAllCustomerResponseItem> = [
        {
            title: 'ID',
            dataIndex: 'customerCode',
            key: 'customerCode',
            sorter: (a, b) => a.customerCode.length - b.customerCode.length,
        },
        {
            title: t(translateCodes.NAME),
            dataIndex: 'fullName',
            key: 'fullName',
            render: (_, record) => <CustomerFullNameColumn record={record} />,
        },
        {
            title: t(translateCodes.PHONE),
            dataIndex: 'phone',
            key: 'phone',
        },
        // {
        //     title: t(translateCodes.MEMBERSHIP),
        //     dataIndex: 'Rank',
        //     render: (_, record) => {
        //         return record.customerRank?.name ?? '';
        //     },
        // },
        // {
        //     title: t(translateCodes.POINT),
        //     dataIndex: 'loyaltyPoint',
        //     render: (_) => {
        //         return '';
        //     },
        // },
        {
            title: t(translateCodes.GROUP),
            dataIndex: 'Group',
            render: (_, record) => {
                return record.customerGroup?.name ?? '';
            },
        },
        {
            title: t(translateCodes.SOURCE),
            dataIndex: 'Source',
            render: (_, record) => {
                return record.customerSource?.name ?? '';
            },
        },
        {
            title: t(translateCodes.CREATED_ON),
            dataIndex: 'Created',
            render: (_, record) => {
                const createdAt = dayjs(record?.profileCreationTime);
                return formatDate(createdAt);
            },
        },
        {
            title: t(translateCodes.ACTION),
            render: (_, record) => {
                return (
                    <ColumnActions
                        viewDetailUrl={`${CUSTOMER_DETAIL_URL}/${record.id}`}
                        onClickDelete={() => {
                            handleDelete(record);
                        }}
                        onClickEdit={() => {
                            onEdit(record);
                        }}
                    />
                );
            },
        },
    ];

    return (
        <PageLayout
            pageTitleTransCode={translateCodes.SIMPLE_CRM}
            rightHeaderPage={
                <FlexBox justifyContent='right' gap={12}>
                    <ActionCustomer />
                    <CreateCustomerBtn refetchCustomers={refetch} /> 
                </FlexBox>
            }
        >
            <TableCustom
                onPageChange={(page) =>
                    setSearchParams({...searchParams, page: page.toString()})
                }
                onSearch={(value) =>
                    setSearchParams({ ...searchParams, search: value })
                }
                pageInfo={customers?.meta}
                onFilter={showFilterModal}
                loading={isFetching || isLoading}
                titleTableTransCode={translateCodes.CLIENT_LIST}
                placeholderSearchTransCode={translateCodes.CLIENT_PLACEHOLDER_SEARCH}
                columns={columns}
                dataSource={ customers?.items??[]}
                emptyOption={{
                    title: t(translateCodes.ADD_CUSTOMER),
                    description: t(translateCodes.EMPTY_CUSTOMER_DESCRIPTION),
                    actionButton: (
                        <CreateCustomerBtn refetchCustomers={refetch} />
                    )
                }}
            />
        </PageLayout>
    );
};
