import { NotFound } from '@esg/ui';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import React from 'react';
import { Auth } from './auth';
import { Crm } from './crm/Crm';
import { Unauthorized } from './errors';
import { FastPos } from './fast-pos';
import { Home } from './home';
import { ProductTracker } from './product-tracker/ProductTracker';
import { ResourceManagement } from './resource-management';
import { Setting } from './setting';

import { OrganizationManagement } from './organization-management';
import { BranchManagement } from './organization-management/salon-management/BranchManagement';
import { Profile } from './organization-management/profile';
import { TestPage } from './TestPage';
import { Scheduler } from './scheduler';
import { SuccessPlatform } from './success-platform';
import { SaleAnalyzer } from './sale-analyzer';
import { MainLayout } from '@/layouts';
import { ORGANIZATION_MANAGEMENT_URL } from '@/configs';

export const Router = () => {

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path='/test/*'
                    Component={MainLayout}
                >
                    <Route path='scheduler' Component={TestPage} />
                </Route>
                <Route path='/errors/*'>
                    <Route path='unauthorized' Component={Unauthorized} />
                </Route>
                <Route path='/auth/*' Component={Auth} />
                <Route Component={Home}>
                    <Route
                        path='/'
                        Component={MainLayout}
                    >
                        <Route path='scheduler/*' Component={Scheduler} />
                        <Route path='crm/*' Component={Crm} />
                        <Route path='resource-management/*' Component={ResourceManagement} />
                        <Route path='product-tracker/*' Component={ProductTracker} />
                        <Route path='sale-analyzer/*' Component={SaleAnalyzer} />
                        <Route path='fast-pos/*' Component={FastPos} />
                        <Route path='setting/*' Component={Setting} />
                        <Route path='salon-marketing/*' Component={SuccessPlatform} />
                        <Route path={`${ORGANIZATION_MANAGEMENT_URL}/*`} Component={OrganizationManagement} />
                        <Route path='/' element={<Navigate to={'/scheduler/*'} />} />
                        <Route path='profile' Component={Profile} />
                    </Route>
                    <Route path='*' Component={NotFound} />
                </Route>
                <Route
                    path='/organization-management'
                    Component={OrganizationManagement}
                >
                    <Route path='branch-management' Component={BranchManagement} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
