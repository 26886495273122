import { HttpRequestData, PaginationResponse, httpUtil } from '@esg/framework';
import { PaginationRequest } from '@esg/shared';

import { env } from '../../configs';
import { GetAllCustomerGroupResponseItem } from '../customer-group';
import { GetAllCustomerSourceResponseItem } from '../customer-source';

interface GetAllCustomerRequest extends HttpRequestData {
    readonly query: PaginationRequest;
}

interface CustomerRank {
    name: string;
    id: string;
}

export interface GetAllCustomerResponseItem {
    readonly id: string;
    readonly fullName: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly avatar?: string;
    readonly customerCode: string;
    readonly email: string;
    readonly phone?: string;
    readonly dateOfBirth?: string;
    readonly profileCreationTime: string;
    readonly customerGroup?: GetAllCustomerGroupResponseItem;
    readonly customerRank?: CustomerRank;
    readonly customerSource?: GetAllCustomerSourceResponseItem;
    readonly gender?: number;
    readonly address?: string;
    readonly employeeReferral?: string;
    readonly customerReferral?: string;
    readonly note?: string;
    readonly customerLabel?: number;
    readonly customerGroupId?: string;
    readonly customerSourceId?: string;
}

export const getAllCustomers = httpUtil.createHttpRequestMeta<GetAllCustomerRequest, PaginationResponse<GetAllCustomerResponseItem>>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/customers',
    method: 'GET',
    authentication: 'bearer'
});