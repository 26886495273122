import { FlexBox, InputSearch, Spacer, Text } from '@esg/ui';
import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib';
import React, { useMemo } from 'react';
import { CreateSaleDetailRequest } from '@esg/business-pos/api/sale-detail';
import { GetDetailSaleResponse } from '@esg/business-pos';
import { GetAllProductCategoryResponseItem } from '@esg/business-management/api/product-category';
import MenuProductItem from './menu-item/MenuProductItem';
import { searchHelper } from '@/helpers';

interface ProductTabTabProps {
    readonly productCategories?: GetAllProductCategoryResponseItem[];
    readonly handleCreateSaleDetail: (saleDetailValue: CreateSaleDetailRequest['body']) => void;
    readonly sale: GetDetailSaleResponse
}

export default function ProductTab(props: ProductTabTabProps) {
    const { productCategories, handleCreateSaleDetail, sale } = props;

    const [searchValue, setSearchValue] = React.useState<string>('');

    const productCategoriesSearch = useMemo(() => {
        if (!searchValue) return productCategories;
        return searchHelper.searchItemInCategories(productCategories ?? [], searchValue, 'products');
    }, [productCategories, searchValue]);

    const menuOptions: CollapseProps['items'] = productCategoriesSearch?.map((category) => {
        return {
            key: category.id,
            label: <><Text textTransform='capitalize' fontSize={16} fontWeight={700}>{`${category.name}`}</Text> ({category?.products?.length ?? 0}) </>,
            children:
                <FlexBox direction='column' gap={16}>
                    {category.products?.map((product) =>
                        <MenuProductItem key={product.id} product={product} onOrderItem={handleCreateSaleDetail} sale={sale} />
                    )}
                </FlexBox>
        };
    });

    return (
        <Spacer type='margin' top={16}>
            <InputSearch height={40} placeholder='Search an item' onChange={(e) => setSearchValue(e.target.value)} />
            <Spacer type='margin' top={16}>
                <Collapse
                    bordered={false}
                    expandIconPosition='end'
                    items={menuOptions}
                />
            </Spacer>
        </Spacer>
    );
}

