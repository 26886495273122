import { FlexBox, Spacer, Text, globalDrawerState, useHttpCommand, useLocalesFormat } from '@esg/ui';
import { HeaderDrawer } from '@esg/ui/components/drawer/HeaderDrawer';
import { Badge, Spin, Tag } from 'antd';
import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { SaleStatus, updateSaleActive } from '@esg/business-pos';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { currentSaleState } from '@/states';
import { saleEvents } from '@/event/saleEvent';

const saleStatus = (status?: SaleStatus) => {
    switch(status) {
    case SaleStatus.Paid:
        return <Tag><Badge size='small' status='default' text='Paid'/></Tag>;
    case SaleStatus.Unpaid:
        return <Tag><Badge size='small' status='processing' text='Unpaid'/></Tag>;
    case SaleStatus.Cancelled:
        return <Tag><Badge size='small' status='error' text='Cancelled'/></Tag>;
    default:
        return <Tag><Badge size='small' status='processing' text='Unpaid'/></Tag>;
    }
};
interface SaleOrderHeaderProps {
    height?: number | string;   
}

export default function SaleOrderHeader(props: SaleOrderHeaderProps) {
    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const {height} = props;
    const resetDrawerState = useResetRecoilState(globalDrawerState);
    const [currentSale] = useRecoilState(currentSaleState);

    const { mutateAsync: onUpdateSaleActive } = useHttpCommand(updateSaleActive, {
        onSuccess: () => {
            saleEvents.onRefetchSaleActive.emit();
            saleEvents.onRefetchSaleList.emit();
            resetDrawerState();
        }
    });

    const handleMinimize = useCallback(async () => {
        await onUpdateSaleActive({pathData: {id: currentSale?.id},
            body: {
                id: currentSale?.id, 
                isActive: true
            }
        });
    }, [currentSale, onUpdateSaleActive]);
    
    const renderHeader = () => {
        if(!currentSale) {
            return <Spin size='small' />;
        }

        return (
            <Spacer>
                <FlexBox gap={16} alignItems='center'>
                    <Text fontSize={20} fontWeight={700} >ID: {currentSale?.code}</Text>
                    {saleStatus(currentSale.status)}
                </FlexBox>
                <Spacer />
                <Text fontSize={14} fontWeight={400} >{formatDate(currentSale?.date,  'h:mm a ddd, ' + currentLocale?.format )}</Text>
            </Spacer>
        );
    };

    return (
        <FlexBox backgroundColor='#fff' alignItems='center' height={height}>
            <Spacer type='padding' left_right={16} width='100%'>
                <HeaderDrawer 
                    drawerTitle={renderHeader()}
                    onMinimize={handleMinimize}
                    onClose={()=> {
                        saleEvents.onRefetchSaleList.emit();
                        resetDrawerState();
                    }}
                />
            </Spacer>
        </FlexBox>
    );
}
