import { Block } from '@esg/ui';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import { flatten } from 'lodash';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { mainLayoutTheme } from '../@shared';

import { mainMenu, mainMenuFixedItems } from '@/configs';
import { IMenuItem } from '@/Types';

const layoutSiderStyle: React.CSSProperties = {
    overflowY: 'auto',
    height: `calc(100vh - ${mainLayoutTheme.headerHeight}`,
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    marginTop: mainLayoutTheme.headerHeight + 3,
    borderRight: '1px solid #0505050f'
};

type MenuItem = Required<MenuProps>['items'][number];

interface IMainLayoutSiderProps {
    readonly collapsed: boolean;
    readonly setCollapsed: (value: boolean) => void;
}

export function MainLayoutSider(props: IMainLayoutSiderProps) {
    const { collapsed, setCollapsed } = props;
    const navigate = useNavigate();

    const getSelectedKey = () => {
        const currentPath = location.pathname;
        const pathStr = currentPath.split('/');
        const key = '/' + pathStr[1];

        return key;
    };

    const currentKey = getSelectedKey();

    const SiderActionIconSrc = collapsed ? mainLayoutTheme.siderActionRightIcon : mainLayoutTheme.siderActionLeftIcon;

    return (
        <Layout.Sider
            width={mainLayoutTheme.siderWidth}
            theme='light'
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            style={layoutSiderStyle}
            className='main-layout-sider'
        >
            <Block>
                <img
                    src={SiderActionIconSrc}
                    className={`sider-action ${collapsed ? 'collapsed' : ''}`}
                    onClick={() => setCollapsed(!collapsed)}
                />
                <Menu
                    mode='inline'
                    selectedKeys={[currentKey]}
                    items={collapsed ? itemsCollapse : items}
                    defaultOpenKeys={mainMenu.map(o => o.url ?? o.label)}
                    onSelect={(o) => {
                        if (checkCollapse(o.keyPath[0], mainMenu)) {
                            setCollapsed(true);
                        }else{
                            setCollapsed(false);
                        }
                        navigate(o.key);
                    }}
                />
                <Menu
                    mode='inline'
                    className='menu-fixed'
                    items={itemsFixed}
                    selectedKeys={[currentKey]}
                    onSelect={({ key }) => {
                        setCollapsed(true);
                        navigate(key);
                    }}
                />
            </Block>
        </Layout.Sider>
    );
}

const checkCollapse = (keyPath: string, menuList: IMenuItem[]) => {
    const listChildren = menuList.flatMap(o => o.children);
    const selectCollapse = listChildren.find(o=>o?.url == keyPath)?.autoCollapse;
    if (selectCollapse) {
        return 1;
    }
    return 0;
};

const getIconComponent = (item: IMenuItem) => {
    if (!item.icon) {
        return null;
    }

    if (item.iconActive) {
        return (
            <i>
                <img src={item.icon} alt='icon' />
                <img src={item.iconActive} alt='icon' className='active' />
            </i>
        );
    }

    return (
        <i>
            <img src={item.icon} alt='icon' />
        </i>
    );
};

const getMenuItem = (item: IMenuItem): MenuItem => ({
    label: (
        <Link to={item.url!}>
            {item.label}
        </Link>
    ),
    key: item.url ?? item.label,
    icon: getIconComponent(item),

    children: item.children?.filter(f => !f.disable).map(childItem => {
        return {
            label: (
                <Link to={childItem.url!}>
                    {childItem.label}
                </Link>
            ),
            icon: getIconComponent(childItem),
            key: childItem.url!,
            className: childItem.isComingSoon ? 'coming-soon' : '',
        };
    })
});

const enabledItems = mainMenu.filter(f => !f.disable);

const items: MenuItem[] = enabledItems.map(item => getMenuItem(item));

const itemsCollapse: MenuItem[] = flatten(enabledItems.map(item => {
    if (item.children && item.children.length > 0) {
        return item.children.filter(f => !f.disable).map(o => getMenuItem(o));
    }

    return getMenuItem(item);
}));

const itemsFixed = mainMenuFixedItems.filter(f => !f.disable).map(item => getMenuItem(item));
