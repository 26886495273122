/* eslint-disable @typescript-eslint/no-explicit-any */
import { translateCodes } from '@/locales';

export const customerRules = {
    phone: {
        pattern: {
            value: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
            message: translateCodes.PHONE_NUMBER_IS_INVALID,
        },
    },
    lastName: (t: any) => ({
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_LAST_NAME, { number: 50 }),
        },
        minLength: {
            value: 2,
            message: t(translateCodes.LAST_NAME_MIN_LENGTH),
        },
        pattern: {
            value: /^[^!@#$%^&*(),.?":{}|<>]/,
            message: translateCodes.LAST_NAME_INVALID,
        },
    }),
    firstName:(t: any) => ({
        required: translateCodes.FIRST_NAME_REQUIRED,
        maxLength: {
            value: 50,
            message: t(translateCodes.MAX_LENGTH_FIRST_NAME, { number: 50 }),
        },
        minLength: {
            value: 2,
            message: t(translateCodes.FIRST_NAME_MIN_LENGTH),
        },
        pattern: {
            value: /^[^!@#$%^&*(),.?":{}|<>]/,
            message: translateCodes.FIRST_NAME_INVALID,
        },
    }),
    email: (t: any) => ({
        required: translateCodes.EMAIL_REQUIRED,
        pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
            message: translateCodes.EMAIL_INVALID,
        },
        maxLength: {
            value: 255,
            message: t(translateCodes.MAX_LENGTH_EMAIL, { number: 255 }),
        },
    }),
    address: (t: any) => ({
        maxLength: {
            value: 200,
            message: t(translateCodes.MAX_LENGTH_ADDRESS, { number: 200 })
        },
    }),
    note: (t: any) => ({
        maxLength: {
            value: 100,
            message: t(translateCodes.MAX_LENGTH_NOTE, { number: 100 }),
        },
    }),
};
