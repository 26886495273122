import {
    Block,
    FlexBox,
    Spacer,
    Text,
    globalModalState,
    useHttpQuery,
    useLocalesFormat,
} from '@esg/ui';
import React from 'react';
import { GetDetailSaleResponse } from '@esg/business-pos';
import { SaleDetailGetAllResponseItem } from '@esg/business-pos/api/sale-detail';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { resourceApis } from '@esg/business-resource-management';
import { dayjs } from '@esg/shared';
import { useTranslation } from 'react-i18next';
import { currentLocaleState } from '@esg/ui/state/currentLocale';
import { Segmented } from 'antd';
import EditSaleDetailForm from '../../EditSaleDetailForm';
import SaleOrderFooter from './order-layout/SaleOrderFooter';
import SaleGeneralInfo from './general-info/SaleGeneralInfo';
import SaleOrderHeader from './order-layout/SaleOrderHeader';
import { OrderActivities } from './order-activities/OrderActivities';
import { currentSaleState } from '@/states';
import { translateCodes } from '@/locales';

interface SaleOrderProps {
    readonly handleUpdateSale: (sale: GetDetailSaleResponse) => Promise<void>;
    readonly handleUpdateSaleDetail: (saleDetail: SaleDetailGetAllResponseItem) => Promise<void>;
    readonly handleDeleteSaleDetail: (id: string) => Promise<void>;
    readonly isLoading?: boolean;
}

export default function SaleOrder(props: SaleOrderProps) {
    const { handleUpdateSaleDetail, handleDeleteSaleDetail, handleUpdateSale, isLoading } = props;
    const { t } = useTranslation();

    const { formatDate } = useLocalesFormat();
    const [currentLocale] = useRecoilState(currentLocaleState);
    const [currentSale] = useRecoilState(currentSaleState);
    
    const [globalModal, setGlobalModal] = useRecoilState(globalModalState);
    const [tabActive, setTabActive] = React.useState<number>(0);
    const resetModalState = useResetRecoilState(globalModalState);

    const { data: employees } = useHttpQuery(resourceApis.getAllEmployees);

    const showModalEditSaleDetail = (saleDetail: SaleDetailGetAllResponseItem) => {
        setGlobalModal({
            ...globalModal,
            isOpen: true,
            titleTransCode: (
                <FlexBox direction='column'>
                    <Text>{`${t(translateCodes.EDIT)} ${saleDetail?.objectName}`}</Text>
                    <Text fontWeight={400}>
                        {formatDate(dayjs(), 'h:mm a ddd, ' + currentLocale?.format)}
                    </Text>
                </FlexBox>
            ),
            content: (
                <EditSaleDetailForm
                    handleSubmit={handleUpdateSaleDetail}
                    onCancel={resetModalState}
                    defaultValues={saleDetail}
                    employees={employees?.items}
                    isLoading={isLoading}
                />
            ),
        });
    };

    const headerHeight = 70;
    const footerHeight = 115;
    const segmentedHeight = 72;
    const containerHeight = `calc(100% - ${headerHeight + footerHeight + segmentedHeight}px)`;

    return (
        <Block width='33vw' className='sale-order'>
            <SaleOrderHeader height={headerHeight} />
            <Spacer top_bottom={16} left_right={24}>
                <Segmented
                    style={{ width: '100%' }}
                    size='large'
                    options={[
                        { value: 0, label: t(translateCodes.GENERAL_INFO) },
                        { value: 1, label: t(translateCodes.ACTIVITY_Log) },
                    ]}
                    value={tabActive}
                    onChange={setTabActive}
                />
            </Spacer>
            <Block height={containerHeight} overflow='auto'>
                {tabActive === 0 && (
                    <SaleGeneralInfo
                        isLoading={isLoading}
                        sale={currentSale!}
                        handleUpdateSale={handleUpdateSale}
                        handleDeleteSaleDetail={handleDeleteSaleDetail}
                        handleUpdateSaleDetail={showModalEditSaleDetail}
                    />
                )}
                {tabActive === 1 && (
                    <OrderActivities sale={currentSale!} />
                )}
            </Block>
            <SaleOrderFooter 
                height={footerHeight} 
                sale={currentSale!} 
                handleUpdateSale={handleUpdateSale}
            />
        </Block>
    );
}
