import './CustomerDetailTable.scss';
import { FlexBox, Spacer, Text } from '@esg/ui';
import { Segmented } from 'antd';
import React, { useState } from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { SegmentedValue } from 'antd/es/segmented';
import { CustomerAppointmentTable } from './customer-table/CustomerAppointmentTable';
import { CustomerSaleTable } from './customer-table/CustomerSaleTable';
import { CustomerProductTable } from './customer-table/CustomerProductTable';
import { translateCodes } from '@/locales';

interface TabCustom {
    [key: string]: React.ReactNode
}
interface CustomerDetailTableProps {
    customer?: GetCustomerDetailResponse;
    refetchOverview: () => void;
}

export const CustomerDetailTable = ({ customer,refetchOverview }: CustomerDetailTableProps) => {

    const [tabValue, setTab] = useState<SegmentedValue>('sale');

    const tabOptions = [
        {
            label: <Text translateCode={translateCodes.SALES} fontSize={16} />,
            value: 'sale',
        },
        {
            label: <Text translateCode={translateCodes.APPOINTMENTS} fontSize={16} />,
            value: 'appointment',
        },
        {
            label: <Text translateCode={translateCodes.PRODUCTS} fontSize={16} />,
            value: 'product',
        },
    ];

    const tabs: TabCustom = {
        'sale': <CustomerSaleTable customer={customer!} refetchOverview={refetchOverview}/>,
        'appointment': <CustomerAppointmentTable customer={customer} />,
        'product': <CustomerProductTable customer={customer!} refetchOverview={refetchOverview}/>,
    };

    return (
        <FlexBox direction='column'>
            <Segmented
                className='customer-segmented'
                options={tabOptions}
                onChange={setTab}
            />  
            <Spacer height={16} />
            {customer&& tabs[tabValue]}
        </FlexBox>

    );
};
