import { GetAllCustomerResponseItem } from '@esg/business-crm';
import { FlexBox, Text } from '@esg/ui';
import { Avatar } from 'antd';
import React from 'react';

interface ColumnNameProps {
    record: GetAllCustomerResponseItem;
}

export const CustomerFullNameColumn = (props: ColumnNameProps) => {
    const { record } = props;
    const nameAvatar = record?.firstName?.[0] + (record?.lastName ? record?.lastName?.[0] : record?.firstName?.[1]??'');

    return (
        <FlexBox gap={12} alignItems='center'>
            <Avatar
                key={record.id}
                src={record.avatar}
                style={{ backgroundColor: '#F2F4F7' }}
            >
                {nameAvatar}
            </Avatar>

            <FlexBox direction='column'>
                <Text fontSize={14} fontWeight={600} lineHeight='20px'>
                    {record.fullName}
                </Text>
                <Text
                    fontSize={12}
                    fontWeight={400}
                    lineHeight='18px'
                    type='secondary'
                >
                    {record.email}
                </Text>
            </FlexBox>
        </FlexBox>
    );
};
