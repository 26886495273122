import { genderUtil } from '@esg/shared';
import {
    AppButton,
    Block,
    FlexBox,
    globalDrawerState,
    Header,
    Spacer,
    Text,
    IconPen,
    IconPhone,
    IconCalendar,
    IconMail,
    IconFace,
    IconLocation,
    IconSource,
    IconGroup,
    IconBorn,
    IconRank,
    useLocalesFormat,
    globalModalState,
} from '@esg/ui';
import {
    Avatar,
    Card,
    Flex,
    theme,
} from 'antd';
import React from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllCustomerResponseItem, GetCustomerDetailResponse } from '@esg/business-crm';

import { useTranslation } from 'react-i18next';
import { translateCodes } from '@/locales';
import { CustomerForm } from '@/components/form/customer/CustomerForm';
import { CustomerLabelTag } from '@/components/customer/CustomerLabelTag';
import { useCustomerDelete } from '@/hooks/useCustomerDelete';
import { CustomerDeleteConfirm } from '@/components/customer/CustomerDeleteConfirm';
import { CRM_URL } from '@/configs';

const { useToken } = theme;

interface CustomerInfoProps {
    customer?: GetCustomerDetailResponse;
    loading?: boolean;
    refetch: () => void;
}

export const CustomerInfo = ({ customer, loading, refetch }: CustomerInfoProps) => {
    const { t } = useTranslation();
    const { formatDate } = useLocalesFormat();
    const { token } = useToken();
    const [, setDrawerState] = useRecoilState(globalDrawerState);
    const [, setGlobalModal] = useRecoilState(globalModalState);
    const resetDrawerState = useResetRecoilState(globalDrawerState);

    const onDelete = useCustomerDelete(refetch, CRM_URL);

    const handleDelete = React.useCallback(
        (customer: GetAllCustomerResponseItem) => {
            setGlobalModal({
                content: (
                    <CustomerDeleteConfirm {...{ customer }} />
                ),
                isOpen: true,
                onOk: () => {
                    onDelete(customer.id);
                },
                showModalDelete: true,
            });
        },
        [onDelete, setGlobalModal]
    );

    const handleEdit = () => {
        setDrawerState({
            subtitle: t(translateCodes.CREATED_ON) + ' ' + formatDate(customer?.profileCreationTime ?? ''),
            isOpen: true,
            titleTransCode: translateCodes.EDIT_CUSTOMER,
            content: (
                <CustomerForm
                    onDeleted={() => handleDelete(customer as GetAllCustomerResponseItem)}
                    onSuccess={() => {
                        resetDrawerState();
                        refetch();
                    }} 
                    defaultValues={customer as GetAllCustomerResponseItem} 
                />
            ),
        });
    };

    const infos = React.useMemo(() => {
        return [
            {
                icon: <IconPhone color={token.colorPrimary} />,
                label: customer?.phone
            },
            {
                icon: <IconBorn color={token.colorPrimary} />,
                label:
                    customer?.dateOfBirth ? t(translateCodes.BORN_ON)+' ' + formatDate(customer?.dateOfBirth) : '-'
            },
            {
                icon: <IconSource />,
                label: customer?.customerSource?.name
            },
            {
                icon: <IconMail color={token.colorPrimary} />,
                label: customer?.email
            },

            {
                icon: <IconFace color={token.colorPrimary} />,
                label: genderUtil(customer?.gender)
            },
            {
                icon: <IconGroup/>,
                label: customer?.customerGroup?.name
            },

            {
                icon: <IconLocation color={token.colorPrimary} />,
                label: customer?.address
            },

            {
                icon: <IconCalendar color={token.colorPrimary} />,
                label: t(translateCodes.START_ON) + ' ' + formatDate(customer?.profileCreationTime ?? '')
            },
            {
                icon: <IconRank />,
                label: customer?.note
            },
        ];
    }, [token.colorPrimary, customer?.phone, customer?.dateOfBirth, customer?.customerSource?.name, customer?.email, customer?.gender, customer?.customerGroup?.name, customer?.address, customer?.profileCreationTime, customer?.note, t, formatDate]);

    const nameAvatar = customer?.firstName?.[0] + (customer?.lastName ? customer?.lastName?.[0] : customer?.firstName?.[1]??'');

    return (
        <FlexBox direction='column'>
            <FlexBox justifyContent='space-between' alignItems='end'>
                <Text translateCode={translateCodes.PERSONAL_INFO} fontWeight='bold' fontSize={18} />
                <Flex align='center'>
                    <AppButton
                        onClick={handleEdit}>
                        <FlexBox gap={6}>
                            <IconPen />
                            <Text translateCode={translateCodes.EDIT} />
                        </FlexBox>
                    </AppButton>
                </Flex>
            </FlexBox >
            <Spacer height={10} />
            <Card className='customer-info' loading={loading} style={{ width: '100%' }} >
                <FlexBox alignItems='center' justifyContent='space-between' >
                    <Spacer left={12} right={24} width={150}>
                        <FlexBox alignItems='center' width='150px' position='relative' direction='column' gap={16}>
                            <Avatar
                                size={86}
                                src={customer?.avatar}
                                style={{ backgroundColor: '#F2F4F7' }}
                            >
                                {nameAvatar}
                            </Avatar>
                            <Block position='absolute' top={72} textAlign='center' width='100%'>
                                <CustomerLabelTag customer={customer}/>
                            </Block>
                            <Header level={2} translateCode={customer?.fullName} textAlign='center'/>
                        </FlexBox>
                    </Spacer>
                    <FlexBox gap={12} flexWrap='wrap'>
                        {infos.map((o, i) => {
                            return (
                                <FlexBox key={i} className='customer-info-item' gap={8} alignItems='center' width={244} flex='none'>
                                    {o.icon}
                                    {o.label ? <Text maxWidth={200} translateCode={o.label} /> : '-'}
                                </FlexBox>
                            );
                        })}
                    </FlexBox>
                </FlexBox>
            </Card>
        </FlexBox>
    );
};
