import { AppButton, Translate, useHttpCommand , managementProfileState } from '@esg/ui';
import { Image } from 'antd';
import React from 'react';

import { useRecoilState } from 'recoil';
import { createSale } from '@esg/business-pos';
import { mainLayoutTheme } from '../../@shared';

import { translateCodes } from '@/locales';

import useOpenSaleFrom from '@/pages/fast-pos/sale/hook/useOpenSaleFrom';

export function CreateBillButton() {
    const [currentProfile] = useRecoilState(managementProfileState);
    const { onShowFormSale } = useOpenSaleFrom();

    const { mutateAsync: onCreateSale, isPending } = useHttpCommand(createSale, {
        onSuccess: (response) => {
            onShowFormSale(response);
        }
    });

    const handleCreateSale = async () => {
        await onCreateSale({
            body: {
                branchId: currentProfile?.branch.id,
            }
        });
    };

    return (
        <AppButton 
            onClick={handleCreateSale}
            loading={isPending}
            icon={(
                <Image
                    src={mainLayoutTheme.createBillIcon}
                    preview={false}
                    width={24}
                    height={24}
                />
            )}>
            <span>
                <Translate translateCode={translateCodes.CREATE_BILL} />
            </span>
        </AppButton>
    );
}
