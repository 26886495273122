import {
    FlexBox,
    IconRevenue,
    Text,
    useLocalesFormat,
} from '@esg/ui';
import {
    Avatar,
    Card,
} from 'antd';
import React from 'react';
import { GetCustomerDetailResponse } from '@esg/business-crm';
import { GetOverviewClientDetailResponse } from '@esg/business-report/api/client-detail-report';
import { translateCodes } from '@/locales';

interface CustomerOverviewProps {
    customer?: GetCustomerDetailResponse;
    loading?: boolean;
    reportOverview?: GetOverviewClientDetailResponse
}

export const CustomerOverview = ({ loading , reportOverview}: CustomerOverviewProps) => {
    const { formatCurrency } = useLocalesFormat();

    const infos = React.useMemo(() => {
        return [
            {
                label: translateCodes.TOTAL_SALE,
                value: reportOverview?.totalSale ?? '-',
                color: '#3D8CF5',
            },
            {
                label: translateCodes.REVENUE,
                value: formatCurrency(reportOverview?.totalRevenue ?? 0),
                color: '#17B26A',
            },
            // {
            //     label: translateCodes.MEMBERSHIP,
            //     value: '-',
            //     color: '#BA24D5',
            // },
            // {
            //     label: translateCodes.LOYALTY_POINT,
            //     value: '-',
            //     color: '#FD084A',
            // },
        ];
    }, [formatCurrency, reportOverview?.totalRevenue, reportOverview?.totalSale]);

    return (
        <FlexBox gap={12} flexWrap='wrap'>
            {infos.map((o, i) => {
                return (
                    <Card key={i} loading={loading} style={{ borderRadius: 12, width: 286 }}>
                        <FlexBox className='customer-info-item' gap={8} alignItems='center' flex='none'>
                            <Avatar
                                style={{ backgroundColor: '#E7F1FE' }}
                                size={42}
                                icon={<IconRevenue color={o.color}/>}
                            />
                            <FlexBox direction='column'>
                                <Text translateCode={o.label} />
                                <Text fontWeight='bold'> {o.value}</Text>
                            </FlexBox>
                        </FlexBox>
                    </Card>
                );
            })}
        </FlexBox>
    );
};
