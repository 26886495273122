import { PlusCircleOutlined, UserAddOutlined } from '@ant-design/icons';
import { AppAvatar, AppButton, FlexBox, SelectField, Spacer, Text, subDrawerState, useLocalesFormat } from '@esg/ui';
import React, { useCallback } from 'react';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { GetAllCustomerResponseItem } from '@esg/business-crm';
import { theme } from 'antd';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { CustomerForm } from '../../CustomerForm';
import CustomerSelectValue from './CustomerSelectValue';
import CustomerSelectOption from './CustomerSelectOption';
import { translateCodes } from '@/locales';
import useSearchCustomer from '@/hooks/useSearchCustomer';

const { useToken } = theme;

interface CustomerSelectFieldProps {
    readonly name: string;
    readonly handleSelectCustomer?: (customer?: GetAllCustomerResponseItem | null) => void;
    readonly disabled?: boolean;
    readonly isRequired?: boolean;
}

export const CustomerSelectField = (props: CustomerSelectFieldProps) => {
    const { t } = useTranslation();
    const { token } = useToken();
    const { formatDate } = useLocalesFormat();
    const [, setSubDrawerState] = useRecoilState(subDrawerState);
    const resetSubDrawerState = useResetRecoilState(subDrawerState);
    const { setValue, watch } = useFormContext();

    const { customers, setSearchValue, loading, searchValue, refetch } = useSearchCustomer();

    const customerOptions = React.useMemo(() => customers?.map((o) => ({
        ...o,
        label: o.fullName,
        value: o.id,
    })), [customers]);

    const handleChange = useCallback((value?: GetAllCustomerResponseItem | null) => {
        setValue(props.name, value?.id);
        setValue('customer', value);
        props.handleSelectCustomer?.(value);
    }, [props, setValue]);

    const onCreateCustomer = useCallback((clientName?: string) => {
        setSubDrawerState({
            subtitle: t(translateCodes.CREATED_ON) + ' ' + formatDate(dayjs(Date.now())),
            isOpen: true,
            titleTransCode: translateCodes.CREATE_CUSTOMER,
            content: (
                <CustomerForm
                    defaultValues={{
                        firstName: clientName ?? '',
                    }}
                    onSuccess={(response) => {
                        refetch();
                        resetSubDrawerState();
                        handleChange(response!);
                    }}
                />
            )
        });
    }, [formatDate, handleChange, refetch, resetSubDrawerState, setSubDrawerState, t]);

    const dropdownRender = useCallback((options: React.ReactElement) => (
        <>
            {searchValue && (
                <Spacer type='padding' left={12} >
                    <FlexBox 
                        alignItems='center' 
                        gap={12} height={72} 
                        cursor='pointer'  
                        onClick={()=> onCreateCustomer(searchValue)}
                    >
                        <AppAvatar icon={<UserAddOutlined />} size='lg' />
                        <Text fontWeight='600' textAlign='start' fontSize={16}>
                            New client - {searchValue}
                        </Text>
                    </FlexBox>
                </Spacer>
            )}
            {options}
        </>
    ), [onCreateCustomer, searchValue]);

    const customer = watch('customer') as GetAllCustomerResponseItem;
    return (
        <FlexBox direction='column'>
            <FlexBox alignItems='center' justifyContent='space-between' height={40} flex='auto'>
                <Text fontWeight='bold'>
                    {t(translateCodes.CLIENT)}  {props.isRequired && <span style={{ color: 'red' }}>*</span>}
                </Text>
                {!customer && (
                    <AppButton 
                        type='link' 
                        icon={<PlusCircleOutlined />} 
                        onClick={()=> onCreateCustomer()}
                    >
                        <Text color={token.colorPrimary} fontSize={14} fontWeight={600}>{t(translateCodes.CREATE)}</Text>
                    </AppButton>
                )}
            </FlexBox>
            {customer ? (
                <CustomerSelectValue 
                    customer={customer} 
                    onClear={() => handleChange(null)} 
                    disabled={props.disabled} 
                />
            ):(
                <SelectField
                    disabled={props.disabled}
                    filterOption={false}
                    name={props.name}
                    options={customerOptions}
                    showSearch
                    placeholder={translateCodes.SEARCH_CUSTOMER_PLACEHOLDER}
                    onSearch={debounce(setSearchValue, 500)}
                    loading={loading}
                    rules={{ required: t(translateCodes.PLEASE_CHOOSE_CLIENT) }}
                    listHeight={513}
                    optionRender={({ data }) => <CustomerSelectOption customer={data as GetAllCustomerResponseItem} />}
                    dropdownRender={dropdownRender}
                    onChange={(value)=> {
                        handleChange(customers?.find((o) => o.id === value));
                    }}
                />
            )}
        </FlexBox>
    );
};

CustomerSelectField.defaultProps = {
    isRequired: true,
};
