import { Spacer, useSearchParams } from '@esg/ui';
import React, { useMemo, useState } from 'react';

import { dayjs } from '@esg/shared';
import { SchedulerHeader } from './children';
import { useAppointmentFetcher } from './@share/hooks/useAppointmentFetcher';
import { getAppointmentQueryParams } from './@share/schedulerUtils';
import { ISchedulerContextProps, SchedulerProvider } from './@share/SchedulerContext';
import './Scheduler.scss';
import { SchedulerManagement } from './SchedulerManagement';

export interface ViewType {
	readonly viewBy: 'EMPLOYEE' | 'FACILITY';
	readonly viewMode: 'TIMELINE' | 'LIST';
}

export interface AppointmentQueryParams {
	readonly page: number;
	readonly pageSize: number;
	readonly employeeIds: string[];
	readonly currentDate: string;
	readonly serviceIds: string[];
	readonly statuses: number[];
}

export const Scheduler = () => {
    const [initialQueries] = useState<Partial<AppointmentQueryParams>>({
        page: 1,
        pageSize: 1000,
        currentDate: dayjs().startOf('d').toISOString(),
    });
    const [{ viewBy, viewMode }, setViewType] = useState<ViewType>({
        viewBy: 'EMPLOYEE',
        viewMode: 'TIMELINE',
    });

    const [queryParams, setQuery] = useSearchParams<AppointmentQueryParams>(initialQueries, [
        'page',
        'pageSize',
        'employeeIds',
        'statuses',
        'serviceIds',
        'currentDate',
    ]);

    const query = useMemo(() => getAppointmentQueryParams(queryParams), [queryParams]);

    const { appointments, isAppointmentFetching, refetchAppointment, schedulerItems } = useAppointmentFetcher({
        query: query as AppointmentQueryParams,
        viewType: { viewBy, viewMode },
    });

    const schedulerContext = useMemo<ISchedulerContextProps>(
        () => ({
            refetchAppointment,
            isAppointmentFetching,
            viewType: { viewBy, viewMode },
            currentDate: query.currentDate!,
        }),
        [isAppointmentFetching, query.currentDate, refetchAppointment, viewBy, viewMode]
    );

    return (
        <Spacer type='padding' size={24}>
            <SchedulerProvider resources={schedulerContext}>
                <SchedulerHeader
                    setViewType={setViewType}
                    viewType={{ viewBy, viewMode }}
                    query={query as AppointmentQueryParams}
                    setQuery={setQuery}
                />
                <SchedulerManagement
                    appointments={appointments}
                    viewType={{ viewBy, viewMode }}
                    schedulerItems={schedulerItems}
                    refetchAppointment={refetchAppointment}
                    isAppointmentFetching={isAppointmentFetching}
                />
            </SchedulerProvider>
        </Spacer>
    );
};
