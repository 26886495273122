import { Block } from '@esg/ui';
import { Layout, Menu } from 'antd';
import type { MenuProps } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { mainLayoutTheme } from '@/layouts/main-layout/@shared';
import { IMenuItem } from '@/Types';

type MenuItem = Required<MenuProps>['items'][number];

interface IMainLayoutSubSiderProps {
    readonly subMenu: IMenuItem[];
}

export function MainLayoutSubSider(props: IMainLayoutSubSiderProps) {
    const { subMenu } = props;

    const navigate = useNavigate();

    const getSelectedKey = () => {
        const currentPath = location.pathname;
        const pathStr = currentPath.split('/');
        const key = '/' + pathStr[1] + '/' + pathStr[2];

        return key;
    };
    const currentKey = getSelectedKey();

    const enabledItems = subMenu.filter(f => !f.disable);
    const items: MenuItem[] = enabledItems.map(item => getMenuItem(item));

    return (
        <Layout.Sider
            width={mainLayoutTheme.subSiderWidth}
            theme='light'
            className='sub-menu-layout-sider'
            style={{ 
                height: `calc(100vh - ${mainLayoutTheme.headerHeight}`,
                position: 'fixed',
                top: 0,
                bottom: 0,
                overflowY: 'auto',
                marginTop: mainLayoutTheme.headerHeight + 3,
                borderRight: '1px solid #0505050f'
            }}
        >
            <Block>
                <Menu
                    selectedKeys={[currentKey]}
                    defaultOpenKeys={enabledItems.map(o => o.url ?? o.label)}
                    mode='inline'
                    items={items}
                    onSelect={({ key }) => {
                        navigate(key);
                    }}
                />
            </Block>
        </Layout.Sider>
    );
}

const getIconComponent = (item: IMenuItem) => {
    if (!item.icon) {
        return null;
    }

    if (item.iconActive) {
        return (
            <i>
                <img src={item.icon} alt='icon' />
                <img src={item.iconActive} alt='icon' className='active' />
            </i>
        );
    }

    return (
        <i>
            <img src={item.icon} alt='icon' />
        </i>
    );
};

const getMenuItem = (item: IMenuItem): MenuItem => ({
    label: (
        <Link to={item.url!}>
            {item.label}
        </Link>
    ),
    key: item.url ?? item.label,
    icon: getIconComponent(item),

    children: item.children?.filter(f => !f.disable).map(childItem => {
        return {
            label: (
                <Link to={childItem.url!}>
                    {childItem.label}
                </Link>
            ),
            icon: getIconComponent(childItem),
            key: childItem.url!,
            className: childItem.isComingSoon ? 'coming-soon' : '',
        };
    })
});
