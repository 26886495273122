import {
    BOOKKEEPER_URL,
    CRM_URL,
    HELP_CENTER_URL,
    HOME_URL,
    POS_URL,
    PRODUCT_TRACKER_URL,
    RESOURCE_MANAGEMENT_URL,
    SALE_ANALYZER_URL,
    SALON_MARKETING_URL,
    SALON_MARKETPLACE_URL,
    SALON_TRAFFIC_URL,
    SCHEDULER_URL,
    SETTING_URL,
} from '../url';

import { IMenuItem } from '@/Types';

export const mainMenu: IMenuItem[] = [
    {
        label: 'Salon Home',
        icon: '/assets/layout/icons/Home_Icon.svg',
        iconActive: '/assets/layout/icons/Home_Active_Icon.svg',
        autoCollapse: false,
        url: HOME_URL,
    },
    
    {
        label: 'Client Care',
        autoCollapse: false,
        children: [
            {
                url: POS_URL,
                icon: '/assets/layout/icons/Pos_Icon.svg',
                iconActive: '/assets/layout/icons/Pos_Active_Icon.svg',
                label: 'Fast POS',
                autoCollapse: false,
            },
            {
                url: SCHEDULER_URL,
                icon: '/assets/layout/icons/Schedule_Icon.svg',
                iconActive: '/assets/layout/icons/Schedule_Active_Icon.svg',
                label: 'Smart Scheduler',
                autoCollapse: false,
            },
            {
                url: CRM_URL,
                icon: '/assets/layout/icons/CRM_Icon.svg',
                iconActive: '/assets/layout/icons/CRM_Active_Icon.svg',
                label: 'Simple CRM',
                autoCollapse: false,
            }
        ]
    },
    {
        label: 'Salon Management',
        autoCollapse: true,
        children: [
            {
                url: PRODUCT_TRACKER_URL,
                icon: '/assets/layout/icons/Product_Icon.svg',
                iconActive: '/assets/layout/icons/Product_Active_Icon.svg',
                label: 'Product Tracker',
                autoCollapse: true,
            },
            {
                url: RESOURCE_MANAGEMENT_URL,
                icon: '/assets/layout/icons/Resource_Icon.svg',
                iconActive: '/assets/layout/icons/Resource_Active_Icon.svg',
                label: 'Resource Management',
                autoCollapse: true,
            },
            {
                url: BOOKKEEPER_URL,
                icon: '/assets/layout/icons/Bookkeeper_Icon.svg',
                iconActive: '/assets/layout/icons/Bookkeeper_Active_Icon.svg',
                label: 'Bookkeeper',
                disable: true,
                autoCollapse: true,
            },
            {
                url: SALE_ANALYZER_URL,
                icon: '/assets/layout/icons/SaleAnalyzer_Icon.svg',
                iconActive: '/assets/layout/icons/SaleAnalyzer_Active_Icon.svg',
                label: 'Sales Analyzer',
                autoCollapse: true,
            }
        ]
    },
    {
        label: 'Success Platform',
        autoCollapse: true,
        children: [
            {
                url: SALON_TRAFFIC_URL,
                icon: '/assets/layout/icons/SalonTraffic_Icon.svg',
                iconActive: '/assets/layout/icons/SalonTraffic_Active_Icon.svg',
                label: 'Salon Traffic',
                autoCollapse: true,
                disable: true
            },
            {
                url: SALON_MARKETING_URL,
                icon: '/assets/layout/icons/SalonMarketing_Icon.svg',
                iconActive: '/assets/layout/icons/SalonMarketing_Active_Icon.svg',
                autoCollapse: true,
                label: 'Salon Marketing',
            },
            {
                url: SALON_MARKETPLACE_URL,
                icon: '/assets/layout/icons/SalonMarketplace_Icon.svg',
                iconActive: '/assets/layout/icons/SalonMarketplace_Active_Icon.svg',
                label: 'Salon Marketplace',
                isComingSoon: true,
                autoCollapse: false,
                disable: true
            }
        ]
    },
];

export const mainMenuFixedItems: IMenuItem[] = [
    {
        label: 'Settings',
        icon: '/assets/layout/icons/Setting_Icon.svg',
        iconActive: '/assets/layout/icons/Setting_Active_Icon.svg',
        url: SETTING_URL,
        autoCollapse: true,
    },
    {
        label: 'Help Center',
        icon: '/assets/layout/icons/Help_Icon.svg',
        iconActive: '/assets/layout/icons/Help_Active_Icon.svg',
        url: HELP_CENTER_URL,
        disable: true,
        autoCollapse: false,
    },
];