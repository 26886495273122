import React from 'react';
import { IconProps } from '../../Types';

export const IconGroup = (props: IconProps) => {
    return (
        <svg
            {...props}
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M11.3333 14.5H4.66665C1.72665 14.5 0.833313 13.6067 0.833313 10.6667V5.33333C0.833313 2.39333 1.72665 1.5 4.66665 1.5H11.3333C14.2733 1.5 15.1666 2.39333 15.1666 5.33333V10.6667C15.1666 13.6067 14.2733 14.5 11.3333 14.5ZM4.66665 2.5C2.27998 2.5 1.83331 2.95333 1.83331 5.33333V10.6667C1.83331 13.0467 2.27998 13.5 4.66665 13.5H11.3333C13.72 13.5 14.1666 13.0467 14.1666 10.6667V5.33333C14.1666 2.95333 13.72 2.5 11.3333 2.5H4.66665Z'
                fill={props.color} />
            <path d='M12.6666 5.8335H9.33331C9.05998 5.8335 8.83331 5.60683 8.83331 5.3335C8.83331 5.06016 9.05998 4.8335 9.33331 4.8335H12.6666C12.94 4.8335 13.1666 5.06016 13.1666 5.3335C13.1666 5.60683 12.94 5.8335 12.6666 5.8335Z'
                fill={props.color} />
            <path d='M12.6667 8.5H10C9.72667 8.5 9.5 8.27333 9.5 8C9.5 7.72667 9.72667 7.5 10 7.5H12.6667C12.94 7.5 13.1667 7.72667 13.1667 8C13.1667 8.27333 12.94 8.5 12.6667 8.5Z'
                fill={props.color} />
            <path d='M12.6666 11.1665H11.3333C11.06 11.1665 10.8333 10.9398 10.8333 10.6665C10.8333 10.3932 11.06 10.1665 11.3333 10.1665H12.6666C12.94 10.1665 13.1666 10.3932 13.1666 10.6665C13.1666 10.9398 12.94 11.1665 12.6666 11.1665Z'
                fill={props.color} />
            <path d='M5.66669 8.02661C4.72669 8.02661 3.96002 7.25995 3.96002 6.31995C3.96002 5.37995 4.72669 4.61328 5.66669 4.61328C6.60669 4.61328 7.37336 5.37995 7.37336 6.31995C7.37336 7.25995 6.60669 8.02661 5.66669 8.02661ZM5.66669 5.61328C5.28002 5.61328 4.96002 5.93328 4.96002 6.31995C4.96002 6.70661 5.28002 7.02661 5.66669 7.02661C6.05336 7.02661 6.37336 6.70661 6.37336 6.31995C6.37336 5.93328 6.05336 5.61328 5.66669 5.61328Z'
                fill={props.color} />
            <path d='M8 11.3867C7.74666 11.3867 7.52666 11.1933 7.5 10.9333C7.42666 10.2133 6.84666 9.63334 6.12 9.56667C5.81333 9.54001 5.50666 9.54001 5.2 9.56667C4.47333 9.63334 3.89333 10.2067 3.82 10.9333C3.79333 11.2067 3.54666 11.4133 3.27333 11.38C3 11.3533 2.8 11.1067 2.82666 10.8333C2.94666 9.63334 3.9 8.68001 5.10666 8.57334C5.47333 8.54001 5.84666 8.54001 6.21333 8.57334C7.41333 8.68667 8.37333 9.64001 8.49333 10.8333C8.52 11.1067 8.32 11.3533 8.04666 11.38C8.03333 11.3867 8.01333 11.3867 8 11.3867Z'
                fill={props.color} />
        </svg>

    );
};

IconGroup.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#0C6FF3',
};
