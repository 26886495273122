import { HttpRequestData, httpUtil } from '@esg/framework';
import { env } from '../../configs';
import { SaleDetailGetAllResponseItem } from '.';

export interface CreateSaleDetailRequest extends HttpRequestData {
    body: {
        readonly saleId: string;
        readonly objectId: string;
        readonly objectType: string;
        readonly objectPrice: number;
        readonly objectPriceOrigin: number;
        readonly objectName: string;
        readonly quantity: number;
        readonly totalAmount: number;
        readonly taxAmount?: number;
        readonly discount?: number;
        readonly disCountUnit?: string;
        readonly serviceId?: string;
    };
}

export const createSaleDetail = httpUtil.createHttpRequestMeta<
    CreateSaleDetailRequest,
    SaleDetailGetAllResponseItem
>({
    baseUrl: env.apiUrlBase,
    path: '/api/v1/sale-details',
    method: 'POST',
    authentication: 'bearer',
});
