import './SaleFrom.scss';

import { Block, FlexBox, FormBase, FormLayout, globalModalState, useHttpCommand } from '@esg/ui';
import { CreateSaleDetailRequest, GetDetailSaleResponse, SaleDetailGetAllResponseItem, SaleStatus, TipUnit, createSaleDetail, deleteSaleDetail, updateSale, updateSaleDetail } from '@esg/business-pos';
import { GetAllServiceCategoryResponseItem } from '@esg/business-management';

import React, { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { GetAllProductCategoryResponseItem } from '@esg/business-management/api/product-category';
import { viewRequests } from '@esg/framework';
import { useTranslation } from 'react-i18next';
import SaleOrder from './children/sale-order/SaleOrder';
import MenuOrder from './children/menu-order/MenuOrder';
import InitSaleForm from './InitSaleForm';
import { currentSaleState } from '@/states';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';

export interface InitDataSaleForm {
    serviceCategories?: GetAllServiceCategoryResponseItem[];
    productCategories?: GetAllProductCategoryResponseItem[];
}

interface SaleFormProps {
    initDataSaleForm?: InitDataSaleForm;
}

export default function SaleForm(props: SaleFormProps) {
    const { t } = useTranslation();
    const resetModalState = useResetRecoilState(globalModalState);
    const [currentSale, setCurrentSale] = useRecoilState(currentSaleState);
    const [showMenu, setShowMenu] = React.useState<boolean>(true);

    const { mutateAsync: deleteSaleDetailMutate, isPending: loadingDelete } = useHttpCommand(deleteSaleDetail, {
        onSuccess: (response) => {
            const saleDetails = currentSale?.saleDetails?.filter(o => o.id !== response?.id) ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_03),
            });
        }
    });

    const { mutateAsync: updateSaleDetailMutate, isPending: loadingUpdate } = useHttpCommand(updateSaleDetail, {
        onSuccess: (response) => {
            resetModalState();
            const saleDetails = currentSale?.saleDetails?.map(o => o.id === response?.id ? response : o) ?? [];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
            viewRequests.showNotification.send({
                type: 'success',
                message: t(translateCodes.MM_FP_02),
            });
        }
    });

    const { mutateAsync: createSaleDetailMutate, isPending: loadingCreate } = useHttpCommand(createSaleDetail, {
        onSuccess: (response) => {
            const saleDetails = [...(currentSale?.saleDetails ?? []), response];
            const newSale = saleHelper.updateCurrentSale(currentSale!, saleDetails);
            setCurrentSale(newSale);
        }
    });

    const { mutateAsync: updateSaleMutate } = useHttpCommand(updateSale, {
        onSuccess: (response) => {
            setCurrentSale(response);
        }
    });

    const onUpdateSaleDetail = useCallback(async (saleDetail: SaleDetailGetAllResponseItem) => {
        await updateSaleDetailMutate({ pathData: { id: saleDetail.id }, body: saleDetail });
    }, [updateSaleDetailMutate]);

    const onCreateSaleDetail = useCallback(async (saleDetailValue: CreateSaleDetailRequest['body']) => {
        await createSaleDetailMutate({ body: saleDetailValue });
    }, [createSaleDetailMutate]);

    const onUpdateSale = useCallback(async (sale: GetDetailSaleResponse) => {
        await updateSaleMutate({ pathData: { id: sale.id }, body: sale });
    }, [updateSaleMutate]);

    const onDeleteSaleDetail = useCallback(async (id: string) => {
        await deleteSaleDetailMutate({ pathData: { id } });
    }, [deleteSaleDetailMutate]);

    const arrowRightIcon = '/assets/layout/icons/Sider_Arrow_Right_Icon.svg';
    const arrowLeftIcon = '/assets/layout/icons/Sider_Arrow_Left_Icon.svg';

    return (
        <FormBase onSubmit={() => {}} 
            defaultValues={{
                ...currentSale,
                tipUnit: currentSale?.tipUnit ?? TipUnit.Percent,
            }}
        >
            <FormLayout
                className='sale-form'
                width={(showMenu && currentSale?.status === SaleStatus.Unpaid) ? '66vw' : '33vw'}
                footer={<></>}
            >
                <InitSaleForm>
                    <FlexBox className='sale-container'>
                        {currentSale?.status === SaleStatus.Unpaid && (
                            <>
                                <Block cursor='pointer' position='fixed' right={showMenu ? 'calc(66vw - 11px)' : 'calc(33vw - 11px)'} top={24} zIndex={999}>
                                    <img
                                        src={showMenu ? arrowRightIcon : arrowLeftIcon}
                                        onClick={() => setShowMenu(!showMenu)}
                                    />
                                </Block>
                                <MenuOrder
                                    showMenu={showMenu}
                                    initDataSaleForm={props.initDataSaleForm}
                                    updateSaleDetail={onUpdateSaleDetail}
                                    createSaleDetail={onCreateSaleDetail}
                                />
                            </>
                        )}
                        <SaleOrder
                            isLoading={loadingDelete || loadingUpdate || loadingCreate}
                            handleUpdateSale={onUpdateSale}
                            handleDeleteSaleDetail={onDeleteSaleDetail}
                            handleUpdateSaleDetail={onUpdateSaleDetail}
                        />
                    </FlexBox>
                </InitSaleForm>
            </FormLayout>
        </FormBase>
    );
}
