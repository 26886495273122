import React from 'react';
import { IconProps } from '../../Types';

export const IconSource = (props: IconProps) => {
    return (
  
        <svg
            {...props}
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.99998 15.2068C7.53998 15.2068 7.10665 14.9735 6.79998 14.5668L5.79998 13.2335C5.77998 13.2068 5.69998 13.1735 5.66665 13.1668H5.33331C2.55331 13.1668 0.833313 12.4135 0.833313 8.66683V5.3335C0.833313 2.38683 2.38665 0.833496 5.33331 0.833496H10.6666C13.6133 0.833496 15.1666 2.38683 15.1666 5.3335V8.66683C15.1666 11.6135 13.6133 13.1668 10.6666 13.1668H10.3333C10.28 13.1668 10.2333 13.1935 10.2 13.2335L9.19998 14.5668C8.89331 14.9735 8.45998 15.2068 7.99998 15.2068ZM5.33331 1.8335C2.94665 1.8335 1.83331 2.94683 1.83331 5.3335V8.66683C1.83331 11.6802 2.86665 12.1668 5.33331 12.1668H5.66665C6.00665 12.1668 6.39331 12.3602 6.59998 12.6335L7.59998 13.9668C7.83331 14.2735 8.16665 14.2735 8.39998 13.9668L9.39998 12.6335C9.61998 12.3402 9.96665 12.1668 10.3333 12.1668H10.6666C13.0533 12.1668 14.1666 11.0535 14.1666 8.66683V5.3335C14.1666 2.94683 13.0533 1.8335 10.6666 1.8335H5.33331Z'
                fill={props.color} />
            <path d='M7.99998 7.99984C7.62665 7.99984 7.33331 7.69984 7.33331 7.33317C7.33331 6.9665 7.63331 6.6665 7.99998 6.6665C8.36665 6.6665 8.66665 6.9665 8.66665 7.33317C8.66665 7.69984 8.37331 7.99984 7.99998 7.99984Z'
                fill={props.color} />
            <path d='M10.6667 7.99984C10.2933 7.99984 10 7.69984 10 7.33317C10 6.9665 10.3 6.6665 10.6667 6.6665C11.0333 6.6665 11.3333 6.9665 11.3333 7.33317C11.3333 7.69984 11.04 7.99984 10.6667 7.99984Z'
                fill={props.color} />
            <path d='M5.33335 7.99984C4.96002 7.99984 4.66669 7.69984 4.66669 7.33317C4.66669 6.9665 4.96669 6.6665 5.33335 6.6665C5.70002 6.6665 6.00002 6.9665 6.00002 7.33317C6.00002 7.69984 5.70669 7.99984 5.33335 7.99984Z'
                fill={props.color} />
        </svg>

    );
};

IconSource.defaultProps = {
    className: '',
    onClick: () => {},
    color: '#0C6FF3',
};
