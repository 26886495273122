import { GetDetailSaleResponse, SaleStatus, TipUnit } from '@esg/business-pos';
import { FlexBox, InputNumberField, Spacer, Text, TextAreaField, useLocalesFormat } from '@esg/ui';
import React, { useCallback } from 'react';
import { SaleDetailGetAllResponseItem } from '@esg/business-pos/api/sale-detail';
import { Card, Divider, Select, Spin } from 'antd';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import SaleOrderList from './order-item/SaleOrderList';
import { CustomerSelectField } from '@/components/form/customer/children/select-customer/CustomerSelectField';
import { saleHelper } from '@/helpers';
import { translateCodes } from '@/locales';

interface SaleOrderContainerProps {
    sale?: GetDetailSaleResponse;
    handleUpdateSale: (sale: GetDetailSaleResponse) => void;
    handleDeleteSaleDetail: (id: string) => void;
    handleUpdateSaleDetail: (saleDetail: SaleDetailGetAllResponseItem) => void
    isLoading?: boolean;
}

export default function SaleGeneralInfo(props: SaleOrderContainerProps) {
    const { formatCurrency, formatCurrencySymbol } = useLocalesFormat();
    const {setValue, getValues, watch} = useFormContext();
    const { t } = useTranslation();

    const { sale, handleUpdateSale, handleDeleteSaleDetail, isLoading } = props;

    const saleDetails = sale?.saleDetails || [];
    const tipAmount = saleHelper.calcTipAmount(sale!);
    const taxAmount = saleHelper.calcTaxAmount(sale?.saleDetails ?? []);

    const tipUnitSelect = useCallback(() => {
        return (
            <Select 
                onChange={(value) => {
                    setValue('tipUnit', value);
                    handleUpdateSale(getValues() as GetDetailSaleResponse);
                }}
                value={watch('tipUnit')}
            >
                <Select.Option value={TipUnit.Percent}>%</Select.Option>
                <Select.Option value={TipUnit.Amount}>{formatCurrencySymbol()}</Select.Option>
            </Select>
        );
    },[formatCurrencySymbol, getValues, handleUpdateSale, setValue, watch]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleUpdate = useCallback(
        debounce((value: GetDetailSaleResponse) => {
            handleUpdateSale?.(value);
        }, 1000),
        []
    );

    return (
        <Spacer type='padding' left={20} right={20}>
            <CustomerSelectField
                isRequired={false}
                disabled={sale?.status !== SaleStatus.Unpaid}
                name='customerId'
                handleSelectCustomer={(customer) => {
                    handleUpdateSale({
                        ...sale!,
                        customerId: customer?.id
                    });
                }}
            />
            <Spin spinning={isLoading}>
                <SaleOrderList
                    saleDetails={saleDetails}
                    onDeleteSaleDetail={handleDeleteSaleDetail}
                    onEditSaleDetail={props.handleUpdateSaleDetail}
                />
            </Spin>
            <Spacer bottom={8}>
                <Text fontWeight={700} fontSize={16}>{t(translateCodes.TIP)}</Text>
            </Spacer>
            <Card>
                <InputNumberField
                    style={{ flex: 1 }}
                    name='tip'
                    onChange={() => debouncedHandleUpdate(getValues() as GetDetailSaleResponse)}
                    disabled={sale?.status !== SaleStatus.Unpaid}
                    label='Tip Amount'
                    placeholder='Enter a specific amount...'
                    addonBefore={tipUnitSelect()}
                />
            </Card>
            <Spacer type='padding' top_bottom={16}>
                <TextAreaField
                    name='note'
                    placeholder={t(translateCodes.ENTER_YOUR_NOTE)}
                    onChange={() => debouncedHandleUpdate(getValues() as GetDetailSaleResponse)}
                    disabled={sale?.status !== SaleStatus.Unpaid}
                    rows={3}
                    label='Note'
                />
                <Spacer type='margin' top={16} />
                <Card>
                    <FlexBox direction='column' gap={16}>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>Subtotal</Text>
                            <Text fontWeight={600} >{formatCurrency(sale?.subTotal ?? 0)}</Text>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.TAX)}</Text>
                            <Text fontWeight={600}>{formatCurrency(taxAmount)}</Text>
                        </FlexBox>
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={600}>{t(translateCodes.TIP)}</Text>
                            <Text fontWeight={600}>{formatCurrency(tipAmount)}</Text>
                        </FlexBox>
                        <Divider />
                        <FlexBox justifyContent='space-between'>
                            <Text fontWeight={700} fontSize={16}>{t(translateCodes.TOTAL_AMOUNT)}</Text>
                            <Text fontWeight={700} fontSize={16}>{formatCurrency(sale?.totalAmount ?? 0)}</Text>
                        </FlexBox>
                    </FlexBox>
                </Card>
            </Spacer>
        </Spacer>
    );
}
